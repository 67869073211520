/**
 * Helper for app rotues
 */
export const pathTo = () => ({
  home: () => '/',
  login: () => '/login',
  perfil: () => ({
    definirPalavraPasse: () => '/perfil/definir-palavra-passe',
  }),
  admin: () => ({
    freguesias: () => ({
      convidar: () => '/admin/freguesias/convidar-freguesias',
    }),
    municipios: () => ({
      convidar: () => '/admin/municipios/convidar-municipios',
    }),
    convidar: () => '/admin/convidar',
  }),
  pedidos: () => ({
    index: () => '/pedidos',
    postesLuminarias: () => ({
      novo: () => '/pedidos/postes-e-luminarias/novo',
    }),
    pontosDeLuz: () => ({
      novo: () => '/pedidos/luminarias/novo',
    }),
    contratosEventuais: () => ({
      novo: () => '/pedidos/contratos-eventuais/novo',
    }),
    detalhe: (tipoPedido: string, uuid: string) => `/pedidos/${tipoPedido}/${uuid}`,
    print: (uuid: string) => `/pedidos/print/${uuid}`,
  }),
  api: () => ({
    email: () => ({
      convidar: () => '/api/email/convidar',
      notificar: () => '/api/email/notificar',
    }),
    admin: () => ({
      users: (uuid?: string) => (!uuid ? '/api/admin/users' : `/api/admin/users/${uuid}`),
    }),
    eredes: (municipio: string, freguesia?: string) =>
      freguesia ? `/api/eredes/${municipio}/${freguesia}` : `/api/eredes/${municipio}`,
    autenticacao: () => ({
      recuperarPalavraPasse: (email: string) => `/api/autenticacao/recuperar-palavra-passe/${email}`,
      magicLink: (uuid: string) => `/api/autenticacao/magic-link/${uuid}`,
    }),
    callback: () => '/api/auth/callback',
  }),
  relatorios: () => '/relatorios',
})

/**
 * Converts #access_token=blablabla&refresh_token=blabla&... to
 * { access_token: 'blablabla', refresh_token: 'blabla', ...}
 *
 * @param {string} url - The url to get the tokens from
 */
export function getTokensFromUrl(url: string) {
  const hashStr = url.split('#')[1]
  const params = hashStr
    ?.split('&')
    .map((p) => {
      const [name, value] = p.split('=')
      return { name, value }
    })
    .reduce((previous: Record<string, string>, current) => {
      previous[current.name] = current.value
      return previous
    }, {})
  return {
    accessToken: params['access_token'],
    refreshToken: params['refresh_token'],
    tokenType: params['type'],
  } as const
}
